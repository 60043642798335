<script setup>
import { ref, toRefs, watch, onMounted } from "vue";
// import GoodsListDialog from "/src/components/molecules/GoodsListDialog.vue";
import MainAddCardDialog from "/src/components/molecules/MainAddCardDialog.vue";
import { getFunctions, httpsCallable } from "firebase/functions";
// import axios from "axios";
const props = defineProps({
  addresses: Array,
  cardsInfo: Array,
  card_dialog: Boolean,
  errorfortreca: String,
  goodsData: Array,
  orderId: String,
  isLoadingforcredit: Boolean,
  defaultAddress: String,
  defaultCard: String,
  goods_dialogaftercarrierfortreca: Boolean,
  defaultpaymentmethod: String,
  supercommentDone: Boolean,
  suppercommenttype: String,
});

const emit = defineEmits([
  "goodsclose",
  "chooseGoods",
  "chooseTreca",
  "comfirmGoods",
  "getAddressData",
  "getCreditCard",
  "getCreditCard",
  "shippingfeeandorderprice",
  "sendaddress",
  "comfirmBuy",
  "cardclose",
  "comfirmBuyforcarrierpayment",
]);

const { isLoadingforcredit } = toRefs(props);
const { errorfortreca } = toRefs(props);
// const { defaultAddress } = toRefs(props);
const { defaultCard } = toRefs(props);
const { goods_dialogaftercarrierfortreca } = toRefs(props);
const { defaultpaymentmethod } = toRefs(props);
const { supercommentDone } = toRefs(props);
const { suppercommenttype } = toRefs(props);
// const { isLoadingforpayment } = toRefs(props);
const cardclose = () => {
  emit("cardclose");
  comments.value = "";
};

//  デフォルトの判定のためにカード情報と住所を呼び出す
emit("getCreditCard");
// emit("getAddressData");

const items = ["5000円", "3000円", "1000円"];
const firststep = ref(true);
const secondstep = ref(false);
const choosebuycard = ref(null);

const selectitem = ref(null);
const trecaid = ref(null);
const comments = ref("");
const loadingforaddcardload = ref(false);

// const productforsuper = ref(null);
// productforsuper.value = "限定ボイス";
// productforsuper.value = "限定デジタルトレカ";

let maxChars = ref(0);
const isOverLimit = ref(false);

watch(selectitem, (newVal) => {
  switch (newVal) {
    case "5000円":
      maxChars.value = 250;
      break;
    case "3000円":
      maxChars.value = 225;
      break;
    case "1000円":
      maxChars.value = 200;
      break;
  }
});

watch(comments, (newVal) => {
  isOverLimit.value = getTextLength(newVal) > maxChars.value;
});

function getTextLength(text) {
  let length = 0;
  for (let c of text) {
    length += c.charCodeAt(0) < 256 ? 0.5 : 1;
  }
  return Math.floor(length);
}

const confirmcard = () => {
  emit("chooseTreca");

  if (selectitem.value == "5000円") {
    choosebuycard.value = 5000;
    trecaid.value = 1;

    firststep.value = false;

    //デフォルトがある場合、ここで分岐

    if (defaultpaymentmethod.value === "creditcard" && defaultCard.value != null) {
      fifthstep.value = true;
      TrecaUseDefaultforCredit();
      // emit("getAddressData");
    } else {
      thirdstep.value = true;
      // emit("getAddressData");
    }
  }
  if (selectitem.value == "3000円") {
    choosebuycard.value = 3000;
    trecaid.value = 2;
    firststep.value = false;

    //デフォルトがある場合、ここで分岐
    if (defaultpaymentmethod.value === "creditcard" && defaultCard.value != null) {
      fifthstep.value = true;
      TrecaUseDefaultforCredit();
      // emit("getAddressData");
    } else {
      thirdstep.value = true;
      // emit("getAddressData");
    }
  }
  if (selectitem.value == "1000円") {
    choosebuycard.value = 1000;
    trecaid.value = 3;
    firststep.value = false;

    //デフォルトがある場合、ここで分岐
    if (defaultpaymentmethod.value === "creditcard" && defaultCard.value != null) {
      fifthstep.value = true;
      TrecaUseDefaultforCredit();
      // emit("getAddressData");
    } else {
      thirdstep.value = true;
      // emit("getAddressData");
    }
  }
  if (selectitem.value == null) {
    console.log("エラー");
  }

  // emit("confirmcard");
};

const { orderId } = toRefs(props);

const thirdstep = ref(false);

const { cardsInfo } = toRefs(props);
const productsInfoLocal = ref(null);

watch(cardsInfo, () => {
  productsInfoLocal.value = null;
  productsInfoLocal.value = cardsInfo.value;
});

const carderror = ref(false);

watch(supercommentDone, () => {
  console.log(supercommentDone.value);
  isLoading.value = true;

  if (supercommentDone.value == true) {
    isLoading.value = false;
    carderror.value = false;
  } else {
    console.log("エラー");
  }
});

watch(errorfortreca, () => {
  console.log("子のエラー" + errorfortreca.value);
  sixstep.value = false;
  fifthstep.value = true;
  carderror.value = true;
});

const fourthstep = ref(false);
const card = ref();
const cardId = ref();
const choosecard = ref([]);
const toComfirmOrderUsingCard = (index) => {
  card.value = cardsInfo.value[index];

  cardId.value = card.value.id;
  choosecard.value = cardsInfo.value[index];
  fourthstep.value = false;
  fifthstep.value = true;
  shippingfeeandorderprice();
};

const comfirmBuy = () => {
  if (isOverLimit.value) {
    return;
  }

  emit("comfirmBuy", selectitem, choosebuycard, comments, cardId, trecaid);

  fifthstep.value = false;
  sixstep.value = true;
  // comments.value = "";
  // emit("cardclose");
};

const sixstep = ref(false);
const aftercomfirmclose = () => {
  //ここでダイアログをクローズ
  sixstep.value = false;
  firststep.value = true;
  emit("cardclose");
  comments.value = "";
};

const returnsteps = (steps) => {
  if (steps == 2) {
    secondstep.value = false;
    firststep.value = true;
  } else if (steps == 2.5) {
    nextsecondstep.value = false;
    secondstep.value = true;
  } else if (steps == 3) {
    thirdstep.value = false;
    firststep.value = true;
  } else if (steps == 4) {
    fourthstep.value = false;
    thirdstep.value = true;
  } else if (steps == 5) {
    fourthstep.value = true;
    fifthstep.value = false;
  }
};
const selectCreditCardPayment = () => {
  thirdstep.value = false;
  fourthstep.value = true;
  emit("getCreditCard");
};
const usecarrierpaymentflag = ref(false);
// const selectCarrierPayment = () => {
//   thirdstep.value = false;
//   fifthstep.value = true;
//   usecarrierpaymentflag.value = true;
// };
// const changeAddress = () => {
//   chooseaddress.value = [];
//   fifthstep.value = false;
//   secondstep.value = true;
// };
const fifthstep = ref(false);
const changePayment = () => {
  carderror.value = false;
  usecarrierpaymentflag.value = false;
  choosecard.value = [];
  cardId.value = null;
  fifthstep.value = false;
  thirdstep.value = true;
};

const nextsecondstep = ref(false);

//本来は親のcomponentに書く
const order = ref(null);
const dataLenght = ref();
const isDeclinePayment = ref(false);

const getOrderinfo = async () => {
  if (orderId.value != "undefined") {
    const functions = getFunctions();
    functions.region = "asia-northeast1"; // これを追加
    const addMessage = httpsCallable(functions, "Goods_getOrderProducts");
    addMessage({ text: orderId.value }).then((result) => {
      order.value = result.data;
      // オーダー情報の配列の長さを取得する
      dataLenght.value = result.data.length - 1;
    });
  } else {
    isDeclinePayment.value = true;
  }
};

watch(orderId, () => {
  getOrderinfo();
});
//クレジットカード追加
const nextfourthstep = ref(false);
const toAddCard = () => {
  nextfourthstep.value = true;
  fourthstep.value = false;
};
const addcardclose = () => {
  emit("getCreditCard");
  loadingforaddcardload.value = false;
  nextfourthstep.value = false;
  fourthstep.value = true;
};
//stripeの影響で子のコンポーネントでロード画面が動かないためここに実装
const addcardload = () => {
  loadingforaddcardload.value = true;
};
//stripeの影響で子のコンポーネントでロード画面が動かないための実装 カード登録に失敗した場合ロードを消す
const addcardloadoff = () => {
  loadingforaddcardload.value = false;
};
const shippingfeeandorderprice = () => {
  emit("shippingfeeandorderprice");
};
//デフォルトの場合（クレジット）
const TrecaUseDefaultforCredit = async () => {
  choosecard.value = cardsInfo.value.find((card) => card.id == defaultCard.value);
  cardId.value = choosecard.value.id;
  emit("shippingfeeandorderprice");
};
//デフォルトの場合（キャリア）
// const TrecaUseDefaultforCarrier = async () => {
//   console.log(addresses.value);
//   console.log(defaultAddress);
//   chooseaddress.value = addresses.value.find(
//     (address) => address.addressId == defaultAddress.value
//   );
//   usecarrierpaymentflag.value = true;
//   await shippingfeeandorderprice();
// };
onMounted(() => {
  if (goods_dialogaftercarrierfortreca.value == true) {
    // firststep.value = false;
    // sixstep.value = true;
    // getOrderinfo();
  }
});
const isLoading = ref(true);
</script>
<template>
  <!-- <button @click="modalopensample">モーzダルを開く</button> -->
  <teleport to="body">
    <div v-if="card_dialog" class="modal">
      <div class="modal_title">
        スーパーコメント<span v-if="suppercommenttype !== 'なし'"
          >ー{{ suppercommenttype }}付きー</span
        >
      </div>

      <div class="inner">
        <div v-show="firststep">
          <div class="body">
            <p class="textaline_center titlebold">スーパーコメントの金額を選択</p>
            <v-select :items="items" v-model="selectitem"></v-select>
            <p class="textaline_center titlebold">スーパーコメントを入力</p>
            <v-text-field
              label="コメント"
              v-model="comments"
              :class="{ 'over-limit': isOverLimit }"
            ></v-text-field>
            <p v-if="suppercommenttype !== 'なし'">
              ※イベント終了後に{{ suppercommenttype }}が届きます
            </p>
          </div>
          <div class="btn_conteiner unit">
            <button class="return" @click="cardclose">閉じる</button>
            <button class="normal next" @click="confirmcard" :disabled="isOverLimit">
              購入へ進む
            </button>
          </div>
        </div>

        <div v-show="thirdstep">
          <div class="body">
            <div class="unit">
              <p class="textaline_center titlebold">決済方法を選択</p>
              <button class="list next" block @click="selectCreditCardPayment">
                クレジットカード
              </button>

              <!-- <p>※スマートフォンキャリア決済はスーパーチャットではご利用いただけません</p> -->
            </div>
            <div class="btn_conteiner unit">
              <button class="return" @click="returnsteps(3)">戻る</button>
            </div>
          </div>
        </div>
        <div v-show="fourthstep">
          <v-progress-circular
            v-if="isLoadingforcredit == true"
            indeterminate
            color="purple"
          ></v-progress-circular>

          <div class="body">
            <div class="unit">
              <p class="textaline_center titlebold">クレジットカードを選択</p>
              <div class="unit_outline" v-for="(card, index) in productsInfoLocal" :key="index">
                <p class="textaline_margin">
                  カードブランド:
                  <span v-if="card.card.brand === 'visa'">Visa</span>
                  <span v-else-if="card.card.brand === 'mastercard'">Mastercard</span>
                  <span v-else-if="card.card.brand === 'amex'">American Express</span>
                  <span v-else-if="card.card.brand === 'jcb'">JCB</span>
                  <span v-else-if="card.card.brand === 'diners'">Diners Club</span>
                  <span v-else>{{ card.card.brand }}</span>
                  <br />
                  カード番号下4桁: {{ card.card.last4 }}<br />
                  有効期限: {{ card.card.exp_month }}/ {{ card.card.exp_year }}<br />
                </p>
                <p class="textaline_center margn_bottom_reset">
                  <button class="normal next mb-5" @click="toComfirmOrderUsingCard(index)">
                    このカードを使用する
                  </button>
                </p>
              </div>
            </div>
            <div class="btn_conteiner unit">
              <button class="normal next" @click="toAddCard">+ クレジットカードを追加</button>
            </div>
            <div class="btn_conteiner unit">
              <button class="return" @click="returnsteps(4)">戻る</button>
            </div>
          </div>
        </div>

        <div v-show="nextfourthstep">
          <div v-if="loadingforaddcardload" class="loading-overlay">
            <div class="loading-message">ローディング中<span class="loading-dots"></span></div>
          </div>
          <MainAddCardDialog
            @addcardclose="addcardclose"
            @addcardload="addcardload"
            @addcardloadoff="addcardloadoff"
          />
        </div>

        <div v-show="fifthstep">
          <div class="body">
            <h3 class="textaline_center mt-2 mb-5">購入の確認</h3>
            <div v-show="carderror">
              <p class="error">
                このクレジットカードは使用することができません。<br />別のクレジットカードで再度ご購入をお願いします。
              </p>
            </div>
            <div class="btn_conteiner unit">
              <button class="normal next" @click="comfirmBuy()">注文を確定する</button>
            </div>
            <div class="unit">
              <dl class="price">
                <dt>スーパーコメント</dt>
                <dd>{{ comments }}</dd>
              </dl>
            </div>
            <div class="unit">
              <dl class="price">
                <dt>購入金額</dt>
                <dd>{{ selectitem }}（税込・送料無料）</dd>
              </dl>
            </div>
            <p class="textaline_center titlebold mt-3">支払い方法</p>
            <div v-if="usecarrierpaymentflag" class="unit_outline">
              <p>スマートフォンキャリア決済</p>
              <div class="textaline_right mb-2">
                <button @click="changePayment">▶︎支払い方法を変更</button>
              </div>
            </div>
            <div v-else class="unit_outline">
              <p class="textaline_margin">
                カードブランド:
                <span v-if="choosecard.card?.brand === 'visa'">Visa</span>
                <span v-else-if="choosecard.card?.brand === 'mastercard'">Mastercard</span>
                <span v-else-if="choosecard.card?.brand === 'amex'">American Express</span>
                <span v-else-if="choosecard.card?.brand === 'jcb'">JCB</span>
                <span v-else-if="choosecard.card?.brand === 'diners'">Diners Club</span>
                <span v-else>{{ choosecard.card?.brand }}</span>
                <br />
                カード番号下4桁: {{ choosecard.card?.last4 }}<br />
                有効期限: {{ choosecard.card?.exp_month }}/ {{ choosecard.card?.exp_year }}<br />
              </p>
              <div class="textaline_right mb-2">
                <button @click="changePayment">▶︎支払い方法を変更</button>
              </div>
            </div>

            <div class="btn_conteiner unit">
              <button class="normal next mt-3" @click="comfirmBuy()">注文を確定する</button>
            </div>
          </div>
        </div>
        <div v-show="sixstep">
          <!-- ローディング表示 -->
          <div v-if="isLoading" class="loading-overlay">
            <div class="loading-message">ローディング中<span class="loading-dots"></span></div>
          </div>
          <div v-if="!isLoading" class="body">
            <h3 class="finishtitle">スーパーコメント完了</h3>
            <p mt-5>
              スーパーコメントの投稿が完了しました。<span v-if="suppercommenttype !== 'なし'"
                >なお、{{
                  suppercommenttype
                }}は、イベント終了後に登録のメールアドレスに送付いたします。
              </span>
            </p>

            <span v-if="suppercommenttype !== 'なし'">ー{{ suppercommenttype }}付きー</span>
            <div class="btn_conteiner unit">
              <button class="normal" @click="aftercomfirmclose()">閉じる</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<style scoped>
.modal {
  position: absolute;
  bottom: 0;
  background-color: #ffffff;
  width: 100%;
  border-radius: 15px 15px 0 0;
  box-shadow: 0px -10px 10px -5px rgba(0, 0, 0, 0.6);
  z-index: 100;
  touch-action: manipulation; /* モバイルでの拡大を禁止 */
  zoom: 1; /* ブラウザでの拡大を禁止 */
}
.modal .modal_title {
  width: 100%;
  padding: 12px 15px 10px 15px;
  text-align: center;
  font-size: 1rem;
  background: rgb(240, 172, 196);
  color: #ffffff;
  line-height: 1.2em;
  border-radius: 15px 15px 0 0;
  border-top: solid 1px rgb(240, 172, 196);
}
.modal .inner {
  overflow: scroll;
  max-height: 200px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.modal .body {
  padding: 0px 15px;
  font-size: 0.9rem;
  line-height: 1.5em;
}
.modal .body .unit {
  margin-bottom: 15px;
}
.modal .body p {
  margin-bottom: 10px;
}

.modal .body select {
  width: 100%;
  height: 40px;
  border: none;
  border-bottom: #666 solid 2px;
  background-color: #eeeeee;
  padding: 15px 10px;
  border-radius: 0;
  font-size: 1rem;
}
.modal .btn_conteiner {
  text-align: center;
}
.modal button.normal,
.modal button.return {
  padding: 10px 15px;
  font-size: 0.9rem;
  border-radius: 100px;
  border: none;
  margin: 0 5px;
  background: rgb(240, 172, 196);
  color: #ffffff;
}
.modal button.normal:active {
  background: rgb(240, 172, 196);
}
.modal button.return {
  background: rgb(226, 226, 226);
  color: rgb(142 144 147);
}
.modal button.return:active {
  background: rgb(190, 190, 190);
  color: rgb(255 255 255);
}
.modal button.list {
  width: 100%;
  text-align: center;
  padding: 10px 15px;
  font-size: 0.9rem;
  border: none;
  background: rgb(240, 172, 196);
  color: #ffffff;
  border-bottom: solid 1px rgb(132, 163, 206);
}
.modal button.list:active {
  background: rgb(240, 172, 196);
}
.modal .unit_outline {
  background-color: #f5f5f5;
  border-radius: 20px;
  border: solid 1px rgb(226, 226, 226);
  padding: 15px 10px 1px 10px;
  margin-bottom: 30px;
  font-size: 16px;
}

.textaline_center {
  text-align: center;
}
.textaline_left {
  text-align: left;
}
.textaline_right {
  text-align: right;
}
.margn_bottom_reset {
  margin-bottom: 0 !important;
}

/*アドレス追加用  */
.field {
  margin: 0 0 10px;
  vertical-align: middle;
}
.titlebold {
  font-weight: bold;
}
.inputpostcode,
.inputtodohuken,
.inputaddress,
.inputname {
  font-size: 1rem;
  margin: 0 0 0 0px;
  outline: 1px solid;
  padding: 8px;
  border: none;
  border-bottom: #666 solid 1px;
  background-color: #eeeeee;
  outline: none;
  vertical-align: middle;
}
.inputpostcode {
  width: 100px;
}
.inputtodohuken {
  width: 100px;
}
.inputaddress {
  width: 250px;
}
.inputname {
  width: 150px;
}

.field .title {
  font-size: 0.8rem;
  display: inline-block;
  width: 80px;
  vertical-align: middle;
  margin: 0 !important;
}
dl.price {
  display: flex;
  margin: 10px 0;
}
dl.price dt {
  width: 50%;
  border-bottom: solid 1px #aaa;
  padding-bottom: 5px;
}
dl.price dd {
  width: 50%;
  text-align: right;
  border-bottom: solid 1px #aaa;
  padding-bottom: 5px;
}
.dialogtitle {
  margin: 0 0 0 0;
  /* padding: 0.5em 1em; */
  background: #fff;
  border: solid 1px;
  border-radius: 10px 10px 0 0; /*角の丸み*/
  background-color: rgb(142 144 147);
}

.background {
  /* background-color: white;
  width: 300px; */
}
.bottonconteiner {
  margin: 50px 0 20px 0;
  display: flex;
}
.addaddress {
  color: rgb(240, 172, 196);
  font-size: 10px;
}
.next {
  background-color: rgb(218, 57, 144) !important;
}
.return {
  color: rgb(142 144 147);
  font-size: 12px;
}
.gacha_movie {
  width: 100%;
}
.gacha_result {
}
.gacha_result .image {
  text-align: center;
}
.gacha_result .image img {
  width: 50%;
}
.gacha_result .name {
  text-align: center;
}
.gacha_result .rank {
  text-align: center;
}
.productcontainer {
  display: flex;
  /*コレ*/
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.subproductcontaine {
  border: 1px solid rgb(216, 216, 216);
  padding: 10px;
  margin: 10px 1.5% 10px 1.5%;
  width: 30%;
  text-align: center;
  border-radius: 8px;
}
.productpic {
  width: 100%;
  margin-bottom: 0.7em;
}
.productname {
  font-size: 0.8rem;
}
.productrank {
  font-size: 0.8rem;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.5); /* 半透明の灰色背景色 */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* 追加：z-indexプロパティ */
}

.loading-message {
  color: white; /* 白色の文字色 */
  font-size: 24px;
  font-weight: bold;
}

.error {
  color: red;
  font-size: 10px;
}
.over-limit {
  color: red;
}
.finishtitle {
  text-align: center;
}
.textaline_margin {
  margin-left: 15px;
}
@media (min-width: 960px) {
  .modal {
    width: 48%;
    height: 400px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-2%, -50%);
    border-radius: 15px;
  }
  .modal .inner {
    max-height: 300px;
    overflow: auto;
  }
  .unit_outline {
    background-color: #f5f5f5;
    border-radius: 20px;
    border: solid 1px rgb(226, 226, 226);
    padding: 15px 20px 10px 20px;
    margin-bottom: 30px;
    margin-left: 15%;
    margin-right: 15%;
    font-size: 16px;
  }
}
</style>

<script setup>
import { ref, toRefs, watch, onMounted } from "vue";
// import { stripe } from "/src/stripe.js";
import GoodsListDialog from "/src/components/molecules/GoodsListDialog.vue";
import MainAddCardDialog from "/src/components/molecules/MainAddCardDialog.vue";
import { getFunctions, httpsCallable } from "firebase/functions";
import axios from "axios";
// import { is } from "@babel/types";
const props = defineProps({
  addresses: Array,
  cardsInfo: Array,
  goods_dialog: Boolean,
  goodsData: Array,
  orderId: String,
  error: String,
  isLoadingforcredit: Boolean,
  // isLoadingforpayment: Boolean,
  shippingFee: Number,
  totallPrice: Number,
  sumPrice: Number,
  defaultAddress: String,
  defaultCard: String,
  defaultpaymentmethod: String,
  goods_dialogaftercarrier: Boolean,
  user: String,
  vendorname_ja: String,
});

const emit = defineEmits([
  "goodsclose",
  "chooseGoods",
  "comfirmGoods",
  "getAddressData",
  "getCreditCard",
  "shippingfeeandorderprice",
  "comfirmGoodsforcarrierpayment",
  "comfirmGoodsforpaidy",
]);
//  デフォルトの判定のためにカード情報と住所を呼び出す
emit("getCreditCard");
// emit("getAddressData");

const { isLoadingforcredit } = toRefs(props);
// const { isLoadingforpayment } = toRefs(props);
const { goods_dialogaftercarrier } = toRefs(props);
const { shippingFee } = toRefs(props);
const { totallPrice } = toRefs(props);
const { sumPrice } = toRefs(props);
const { defaultAddress } = toRefs(props);
const { defaultCard } = toRefs(props);
const { defaultpaymentmethod } = toRefs(props);
const { vendorname_ja } = toRefs(props);
const { error } = toRefs(props);
const dialogclose = () => {
  fifthstep.value = false;
  sixstep.value = false;
  firststep.value = true;
  videoplayflag.value = true;
  allproductsflag.value = false;
  order.value = null;
  emit("goodsclose");
};
const loadingforaddcardload = ref(false);
// const items = [1, 2, 3, 4, 5, 6];
const firststep = ref(true);
const secondstep = ref(false);
const selectnumber = ref(1);
const choosebuycard = ref(null);
const confirmgoods = async () => {
  emit("chooseGoods", selectnumber);
  emit("getAddressData");
  firststep.value = false;

  //デフォルトがある場合、ここで分岐
  if (
    defaultAddress.value &&
    defaultpaymentmethod.value === "creditcard" &&
    defaultCard.value != null
  ) {
    fifthstep.value = true;
    useDefaultforCredit();
    // emit("getAddressData");
  } else if (defaultAddress.value && defaultpaymentmethod.value === "paidy") {
    fifthstep.value = true;
    useDefaultforPaidy();
    // emit("getAddressData");
  }
  // else if (defaultAddress.value && defaultpaymentmethod.value === "carrier") {
  //   fifthstep.value = true;
  //   console.log("デフォルトがあるよーキャリア決済ー");
  //   useDefaultforCarrier();
  //   // emit("getAddressData");
  // }
  else {
    secondstep.value = true;
    // emit("getAddressData");
  }
};
const { orderId } = toRefs(props);
const { addresses } = toRefs(props);
const addresseslocal = ref(null);
const thirdstep = ref(false);
const chooseaddress = ref([]);
const paidyPaymentId = ref(null);
const toSelectPayment = (index) => {
  secondstep.value = false;
  thirdstep.value = true;
  chooseaddress.value = addresses.value[index];
};
const loadingforaddress = ref(true);
watch(addresses, () => {
  addresseslocal.value = null;
  addresseslocal.value = addresses.value;
  loadingforaddress.value = false;
  // ここで任意のメソッドを呼び出す
});

const { cardsInfo } = toRefs(props);
const productsInfoLocal = ref(null);

watch(cardsInfo, () => {
  productsInfoLocal.value = null;
  productsInfoLocal.value = cardsInfo.value;
});

const fourthstep = ref(false);
const card = ref();
const cardId = ref();
const choosecard = ref([]);
const toComfirmOrderUsingCard = (index) => {
  card.value = cardsInfo.value[index];
  cardId.value = card.value.id;
  choosecard.value = cardsInfo.value[index];
  fourthstep.value = false;
  fifthstep.value = true;
  shippingfeeandorderprice();
  // this.$router.push('/buy/comfirmOrder', () => {});
};
const GoodsListDialogflag = ref(false);

const openGoodsListDialog = () => {
  GoodsListDialogflag.value = true;
};
const sixstep = ref(false);
//本来ここで単価を計算するのは間違っている
const unitPrice = ref(null);
//本来ここで都道府県と市区町村と番地を確認し直すのは間違っている
const todohukenForPaidy = ref(null);
const cityForPaidy = ref(null);
const addressForPaidy = ref(null);
const addaddress2ForPaidy = ref(null);

//本来ここで郵便番号に-を入れるのは間違っている
const postCodeaddHyphenForPaidy = ref(null);

const comfirmBuy = async () => {
  if (usecarrierpaymentflag.value) {
    emit("comfirmGoodsforcarrierpayment", selectnumber, choosebuycard, chooseaddress, cardId);
    fifthstep.value = false;
    sixstep.value = true;
  } else if (usepaidypaymentflag.value) {
    // フラグを更新してUIを変更
    fifthstep.value = false;
    // 本来ここで単価を計算するのは間違っている
    unitPrice.value = (totallPrice.value - shippingFee.value) / selectnumber.value;
    // 本来ここで都道府県を確認し直すのは間違っている
    splitAddress(chooseaddress.value.address01, chooseaddress.value.address02);

    // 本来ここで郵便番号に-を入れるのは間違っている
    postCodeaddHyphenForPaidy.value =
      chooseaddress.value.postNumber.toString().slice(0, 3) +
      "-" +
      chooseaddress.value.postNumber.toString().slice(3);

    // アカウント作成日
    const creationTime = props.user.metadata.creationTime; // "2020-10-25T12:34:56Z" 形式
    const date = new Date(creationTime); // Dateオブジェクトに変換

    // "2020-10-25" の形式に変換
    const formattedDate = `${date.getFullYear()} - ${("0" + (date.getMonth() + 1)).slice(-2)} - ${(
      "0" + date.getDate()
    ).slice(-2)}`;

    const itemName = vendorname_ja.value + "限定グッズ";

    // 本来親で呼び出すべき
    const functions = getFunctions(undefined, "asia-northeast1");
    const calculateChOrderStats = httpsCallable(functions, "calculateChOrderStats");
    const order_count = ref(0);
    const ltv = ref(0);
    const last_order_at = ref(0);
    const last_order_amount = ref(0);

    // 非同期処理の完了を待つ
    try {
      const result = await calculateChOrderStats();
      order_count.value = result.data.chOrderCount;
      ltv.value = result.data.lifetimeValue;
      last_order_at.value = result.data.daysSinceLastOrder;
      last_order_amount.value = result.data.lastOrderAmount;
    } catch (error) {
      console.error("エラーが発生しました:", error);
      return; // エラーが発生した場合は処理を中断
    }
    const paidypublickkey = process.env.VUE_APP_PAIDY_PUBLIC_KEY;
    var config = {
      api_key: paidypublickkey,
      closed: function (callbackData) {
        if (callbackData.status === "authorized") {
          // 支払いが承認されたらPayment IDを取得
          paidyPaymentId.value = callbackData.id;

          // Paidy Payment IDを使って次のステップに進む
          emit("comfirmGoodsforpaidy", selectnumber, chooseaddress, paidyPaymentId.value);
          // フラグを更新してUIを変更
          fifthstep.value = false;
          sixstep.value = true;
        } else {
          sixstep.value = true;
          paidyfronterror.value = true;
          fifthstep.value = true;
          sixstep.value = false;
        }
      },
    };

    const paidyHandler = window.Paidy.configure(config);
    // email: props.user.email,
    // email: "successful.payment@paidy.com",
    var payload = {
      amount: totallPrice.value,
      currency: "JPY",
      store_name: "SukiSukiらいぶすてーしょん",
      buyer: {
        email: props.user.email,
        name1: chooseaddress.value.name,
      },
      buyer_data: {
        account_registration_date: formattedDate,
        ltv: ltv.value,
        order_count: order_count.value,
        last_order_amount: last_order_amount.value,
        last_order_at: last_order_at.value,
      },
      order: {
        items: [
          {
            quantity: selectnumber.value,
            title: itemName,
            unit_price: unitPrice.value,
          },
        ],
        shipping: shippingFee.value,
      },
      shipping_address: {
        line1: addaddress2ForPaidy.value,
        line2: addressForPaidy.value,
        city: cityForPaidy.value,
        state: todohukenForPaidy.value,
        zip: postCodeaddHyphenForPaidy.value,
      },
    };
    // Paidy Checkoutを起動
    paidyHandler.launch(payload);
  } else {
    emit("comfirmGoods", selectnumber, choosebuycard, chooseaddress, cardId.value);
    fifthstep.value = false;
    sixstep.value = true;
  }
};

async function splitAddress(fullAddress, address2) {
  if (!address2) {
    addaddress2ForPaidy.value = "";
  } else {
    addaddress2ForPaidy.value = address2;
  }

  // 日本の都道府県のリスト
  const prefectures = [
    "北海道",
    "青森県",
    "岩手県",
    "宮城県",
    "秋田県",
    "山形県",
    "福島県",
    "茨城県",
    "栃木県",
    "群馬県",
    "埼玉県",
    "千葉県",
    "東京都",
    "神奈川県",
    "新潟県",
    "富山県",
    "石川県",
    "福井県",
    "山梨県",
    "長野県",
    "岐阜県",
    "静岡県",
    "愛知県",
    "三重県",
    "滋賀県",
    "京都府",
    "大阪府",
    "兵庫県",
    "奈良県",
    "和歌山県",
    "鳥取県",
    "島根県",
    "岡山県",
    "広島県",
    "山口県",
    "徳島県",
    "香川県",
    "愛媛県",
    "高知県",
    "福岡県",
    "佐賀県",
    "長崎県",
    "熊本県",
    "大分県",
    "宮崎県",
    "鹿児島県",
    "沖縄県",
  ];

  // 都道府県を抽出
  for (const prefecture of prefectures) {
    if (fullAddress.startsWith(prefecture)) {
      todohukenForPaidy.value = prefecture;
      // 残りの住所を取得
      fullAddress = fullAddress.slice(prefecture.length).trim();
      break;
    }
  }

  // 市区町村と番地を分割
  const cityIndex = fullAddress.search(/[市区町村郡]/);
  if (cityIndex !== -1) {
    cityForPaidy.value = fullAddress.slice(0, cityIndex + 1).trim();
    addressForPaidy.value = fullAddress.slice(cityIndex + 1).trim();
  } else {
    // 市区町村が見つからなかった場合は、すべてを番地として扱う
    cityForPaidy.value = "";
    addressForPaidy.value = fullAddress;
  }

  return {};
}

const returnsteps = (steps) => {
  if (steps == 2) {
    secondstep.value = false;
    firststep.value = true;
  } else if (steps == 2.5) {
    nextsecondstep.value = false;
    secondstep.value = true;
  } else if (steps == 3) {
    emit("getAddressData");
    thirdstep.value = false;
    secondstep.value = true;
  } else if (steps == 4) {
    fourthstep.value = false;
    thirdstep.value = true;
  } else if (steps == 5) {
    fourthstep.value = true;
    fifthstep.value = false;
  } else if (steps == 6) {
    usepaidypaymentflag.value = false;
    carderror.value = false;
    paidyfronterror.value = false;
    thirdstep.value = true;
    fifthstep.value = false;
  }
};
const selectCreditCardPayment = () => {
  thirdstep.value = false;
  fourthstep.value = true;
  // emit("getCreditCard");
};
const selectPaidyPayment = () => {
  emit("shippingfeeandorderprice");
  thirdstep.value = false;
  fifthstep.value = true;
  usepaidypaymentflag.value = true;
  // emit("getCreditCard");
};
const usecarrierpaymentflag = ref(false);
const usepaidypaymentflag = ref(false);
// const selectCarrierPayment = () => {
//   thirdstep.value = false;
//   fifthstep.value = true;
//   usecarrierpaymentflag.value = true;
// };
const changeAddress = () => {
  emit("getAddressData");
  chooseaddress.value = [];
  fifthstep.value = false;
  secondstep.value = true;
};
const fifthstep = ref(false);
const changePayment = () => {
  usepaidypaymentflag.value = false;
  carderror.value = false;
  paidyfronterror.value = false;
  usecarrierpaymentflag.value = false;
  choosecard.value = [];
  cardId.value = null;
  fifthstep.value = false;
  thirdstep.value = true;
};

const goodslistdialogclose = () => {
  GoodsListDialogflag.value = false;
};
const nextsecondstep = ref(false);

const toAddAddress = () => {
  secondstep.value = false;
  nextsecondstep.value = true;
};
//アドレス追加用
const jsonpAdapter = require("axios-jsonp");

const postcode = ref(null);
const todohuken = ref(null);
const moreaddress = ref(null);
const address1 = ref(null);
const address2 = ref(null);
const familyname = ref("");
const firstname = ref("");
const name = ref(null);
const phoneno = ref(null);
const postcodeError = ref(null);

const getaddressfrompostcode = () => {
  const apiURL = "https://api.zipaddress.net/?zipcode=" + postcode.value;

  if (!postcode.value || postcode.value.toString().length != 7) {
    postcodeError.value = "郵便番号はハイフンなしの7桁で入力してください。";
  } else {
    postcodeError.value = null;
  }

  axios.get(apiURL, { adapter: jsonpAdapter }).then(async (res) => {
    const response = res.data;
    todohuken.value = response.pref;
    if (response.city) {
      todohuken.value = response.pref;
      moreaddress.value = response.city + response.town;
    } else {
      console.log("郵便番号間違い");
    }
  });
};

const phoneError = ref(null);
const checkphoneno = () => {
  if (!phoneno.value || phoneno.value.toString().length < 9) {
    phoneError.value = "電話番号を入力してください。";
  } else {
    phoneError.value = null;
  }
};

const sendaddress = async () => {
  address1.value = todohuken.value + moreaddress.value;
  name.value = familyname.value + " " + firstname.value;
  emit("sendaddress", postcode, address1, address2, name, phoneno);
  // emit("getAddressData");

  nextsecondstep.value = false;
  secondstep.value = true;
  loadingforaddress.value = true;
};

//本来は親のcomponentに書く
const order = ref(null);
const dataLenght = ref();
const isDeclinePayment = ref(false);
const videoplayflag = ref(true);
const showimageflag = ref(false);
const productindex = ref(0);
const allproductsflag = ref(false);
const getOrderinfo = async () => {
  if (orderId.value != "undefined") {
    const functions = getFunctions();
    functions.region = "asia-northeast1"; // これを追加
    const addMessage = httpsCallable(functions, "Goods_getOrderProducts");
    addMessage({ text: orderId.value }).then((result) => {
      order.value = result.data;
      // オーダー情報の配列の長さを取得する
      dataLenght.value = result.data.length - 1;
    });
  } else {
    isDeclinePayment.value = true;
  }
};

const videoend = () => {
  videoplayflag.value = false;
  showimageflag.value = true;
};

const nextproduct = () => {
  productindex.value += 1;
  showimageflag.value = false;
  videoplayflag.value = true;
};

const toAllproducts = () => {
  showimageflag.value = false;
  videoplayflag.value = false;
  allproductsflag.value = true;
};

watch(orderId, () => {
  carderror.value = false;
  getOrderinfo();
});
const carderror = ref(false);
const paidyfronterror = ref(false);
// error を監視
watch(error, () => {
  sixstep.value = false;
  fifthstep.value = true;
  carderror.value = true;
});

const loadingForOrder = ref(true);

watch(order, (newValue) => {
  if (newValue === null) {
    loadingForOrder.value = true;
  } else {
    loadingForOrder.value = false;
  }
});

//クレジットカード追加
const nextfourthstep = ref(false);
const toAddCard = () => {
  nextfourthstep.value = true;
  fourthstep.value = false;
};
const addcardclose = () => {
  emit("getCreditCard");
  nextfourthstep.value = false;
  fourthstep.value = true;
};

//stripeの影響で子のコンポーネントでロード画面が動かないためここに実装
const addcardload = () => {
  loadingforaddcardload.value = true;
};
//stripeの影響で子のコンポーネントでロード画面が動かないための実装 カード登録に失敗した場合ロードを消す
const addcardloadoff = () => {
  loadingforaddcardload.value = false;
};
const shippingfeeandorderprice = () => {
  emit("shippingfeeandorderprice");
};
//デフォルトの場合（クレジット）
const useDefaultforCredit = async () => {
  chooseaddress.value = addresses.value.find(
    (address) => address.addressId == defaultAddress.value
  );
  choosecard.value = cardsInfo.value.find((card) => card.id == defaultCard.value);
  cardId.value = choosecard.value.id;
  await shippingfeeandorderprice();
};
//デフォルトの場合（paidy）
const useDefaultforPaidy = async () => {
  chooseaddress.value = addresses.value.find(
    (address) => address.addressId == defaultAddress.value
  );
  usepaidypaymentflag.value = true;
  await shippingfeeandorderprice();
};
//デフォルトの場合（キャリア）
// const useDefaultforCarrier = async () => {
//   console.log(addresses.value);
//   console.log(defaultAddress);
//   chooseaddress.value = addresses.value.find(
//     (address) => address.addressId == defaultAddress.value
//   );
//   usecarrierpaymentflag.value = true;
//   await shippingfeeandorderprice();
// };

onMounted(() => {
  if (goods_dialogaftercarrier.value == true) {
    firststep.value = false;
    sixstep.value = true;
    getOrderinfo();
  }
});
const loading = ref(true);
watch(
  () => totallPrice.value,
  (newTotalPrice) => {
    // totallPriceの値が変更されたときに実行される処理
    console.log(newTotalPrice);
    loading.value = false;
  }
);
const isLoadingforvideo = ref(true);
const handleLoadedData = () => {
  isLoadingforvideo.value = false;
};

const handlePlay = () => {};

//＋ーで商品数を変更
const incrementSelectNumber = () => {
  selectnumber.value++;
};

const decrementSelectNumber = () => {
  if (selectnumber.value > 1) {
    selectnumber.value--;
  }
};
const setSelectNumber = (value) => {
  selectnumber.value = value;
};
//環境変数用
const gachaVideoUrlA = ref(process.env.VUE_APP_GCS_URL + "/live_station_images_common/video_A.mp4");
console.log(gachaVideoUrlA);
const gachaVideoUrlB = ref(process.env.VUE_APP_GCS_URL + "/live_station_images_common/video_B.mp4");
const gachaVideoUrlC = ref(process.env.VUE_APP_GCS_URL + "/live_station_images_common/video_C.mp4");
</script>
<template>
  <!-- <button @click="modalopensample">モーzダルを開く</button> -->
  <teleport to="body">
    <div v-if="goods_dialog" class="modal">
      <div class="modal_title">限定グッズを購入</div>
      <div class="inner">
        <div v-show="firststep">
          <!-- <div class="body">
            <div class="unit">
              <p>購入数</p>
              <v-select :items="items" v-model="selectnumber"></v-select>
              <p class="textaline_right">
                <button class="normal" @click="openGoodsListDialog">グッズ一覧を見る</button>
              </p>
            </div>
          </div> -->
          <div class="body">
            <div class="unit">
              <p class="textaline_center titlebold">数量選択</p>
              <div class="number-selection">
                <div class="control-group">
                  <!-- 追加 -->
                  <div class="quantity-buttons">
                    <button class="quantity-button" @click="decrementSelectNumber()">-</button>
                  </div>
                  <p class="number-display">{{ selectnumber }}</p>
                  <div class="quantity-buttons">
                    <button class="quantity-button" @click="incrementSelectNumber()">+</button>
                  </div>
                </div>
                <div class="numberbox">
                  <div>
                    <p class="textaline_center mt-3">まとめ買い数量を選択</p>
                  </div>
                  <button class="preset-button" @click="setSelectNumber(5)">5回</button>
                  <button class="preset-button" @click="setSelectNumber(10)">10回</button>
                  <button class="preset-button" @click="setSelectNumber(20)">20回</button>
                  <button class="preset-button" @click="setSelectNumber(50)">50回</button>
                </div>
              </div>
              <p class="textaline_right mt-3">
                <button class="normal" @click="openGoodsListDialog">グッズ一覧を見る</button>
              </p>
            </div>
          </div>

          <div class="btn_conteiner">
            <button class="return" @click="dialogclose">閉じる</button>
            <button class="normal next" @click="confirmgoods">くじの購入へ進む</button>
          </div>
        </div>
        <div v-show="secondstep">
          <div class="body">
            <div v-if="loadingforaddress" class="loading-overlay">
              <div class="loading-message">ローディング中<span class="loading-dots"></span></div>
            </div>
            <div class="unit">
              <p class="textaline_center titlebold">お届け先住所を選択</p>
              <div v-for="(address, index) in addresseslocal" :key="index" class="unit_outline">
                <p class="textaline_margin">
                  〒{{ address.postNumber }}<br />
                  住所: {{ address.address01 }}<br />
                  {{ address.address02 }}<br />
                  氏名:{{ address.name }}<br />
                  電話番号: {{ address.phoneNumber }}
                </p>
                <p class="textaline_center margn_bottom_reset">
                  <button class="normal next mt-3 mb-5" @click="toSelectPayment(index)">
                    この住所に届ける
                  </button>
                </p>
              </div>
              <p class="textaline_center">
                <button class="normal next" @click="toAddAddress">+ お届け先を追加</button>
              </p>
            </div>
            <div class="btn_conteiner unit">
              <button class="return" @click="returnsteps(2)">戻る</button>
            </div>
          </div>
        </div>

        <div v-show="nextsecondstep">
          <div class="body">
            <div class="background">
              <p class="textaline_center titlebold">お届け先住所を追加</p>
              <div class="field">
                <p class="title">郵便番号</p>
                <input
                  type="text"
                  v-model="postcode"
                  @input="getaddressfrompostcode"
                  required
                  class="inputpostcode"
                />
                <p class="attention">※郵便番号を入力すると住所は自動で入力されます</p>
                <p class="error" v-if="postcodeError">{{ postcodeError }}</p>
              </div>
              <div class="field">
                <p class="title">都道府県</p>
                <input type="text" v-model="todohuken" required class="inputtodohuken" />
              </div>
              <div class="field">
                <p class="title">ご住所</p>
                <input type="text" v-model="moreaddress" required class="inputaddress" />
              </div>
              <div class="field">
                <p class="title">建物名・<br />部屋番号</p>
                <input type="text" v-model="address2" required class="inputaddress" />
              </div>
              <div class="field">
                <p class="title">姓</p>
                <input type="text" v-model="familyname" required class="inputname" />
              </div>
              <div class="field">
                <p class="title">名</p>
                <input type="text" v-model="firstname" required class="inputname" />
              </div>
              <div class="field">
                <p class="title">電話番号</p>
                <input
                  type="text"
                  v-model="phoneno"
                  @input="checkphoneno"
                  required
                  class="inputname"
                />
                <p class="error" v-if="phoneError">{{ phoneError }}</p>
              </div>
              <div class="btn_conteiner unit">
                <button class="return" @click="returnsteps(2.5)">キャンセル</button>
                <button class="normal next" @click="sendaddress()">お届け先住所を追加</button>
              </div>
            </div>
          </div>
        </div>

        <div v-show="thirdstep">
          <div class="body">
            <div class="unit">
              <p class="textaline_center titlebold">決済方法を選択</p>
              <button class="list next" block @click="selectCreditCardPayment">
                クレジットカード
              </button>
              <!-- <button class="list" block @click="selectCarrierPayment">
                スマートフォンキャリア決済
              </button> -->
              <!-- <button class="paidy" @click="selectPaidyPayment">
                あと払い（ペイディ）
                <img src="img/paidy.png" alt="Paidy" class="paidy-icon" />
              </button> -->
              <button class="list boxtitleforpaidy mt-3" block @click="selectPaidyPayment">
                あと払い（ペイディ）
                <img src="img/paidy_white.png" alt="Paidy" class="paidy-icon" />
              </button>
            </div>
            <div class="btn_conteiner unit">
              <button class="return" @click="returnsteps(3)">戻る</button>
            </div>
          </div>
        </div>
        <div v-show="fourthstep">
          <v-progress-circular
            v-if="isLoadingforcredit == true"
            indeterminate
            color="purple"
          ></v-progress-circular>
          <div class="body">
            <div class="unit">
              <p class="textaline_center titlebold">クレジットカードを選択</p>
              <div class="unit_outline" v-for="(card, index) in productsInfoLocal" :key="index">
                <p class="textaline_margin">
                  カードブランド:
                  <span v-if="card.card.brand === 'visa'">Visa</span>
                  <span v-else-if="card.card.brand === 'mastercard'">Mastercard</span>
                  <span v-else-if="card.card.brand === 'amex'">American Express</span>
                  <span v-else-if="card.card.brand === 'jcb'">JCB</span>
                  <span v-else-if="card.card.brand === 'diners'">Diners Club</span>
                  <span v-else>{{ card.card.brand }}</span>
                  <br />
                  カード番号下4桁: {{ card.card.last4 }}<br />
                  有効期限: {{ card.card.exp_month }}/ {{ card.card.exp_year }}<br />
                </p>
                <p class="textaline_center margn_bottom_reset">
                  <button class="normal next mt-3 mb-5" @click="toComfirmOrderUsingCard(index)">
                    このカードを使用する
                  </button>
                </p>
              </div>
            </div>
            <div class="btn_conteiner unit">
              <button class="normal next" @click="toAddCard">+ クレジットカードを追加</button>
            </div>
            <div class="btn_conteiner unit">
              <button class="return" @click="returnsteps(4)">戻る</button>
            </div>
          </div>
        </div>

        <div v-show="nextfourthstep">
          <div v-if="loadingforaddcardload" class="loading-overlay">
            <div class="loading-message">ローディング中<span class="loading-dots"></span></div>
          </div>
          <MainAddCardDialog
            @addcardclose="addcardclose"
            @addcardload="addcardload"
            @addcardloadoff="addcardloadoff"
          />
        </div>

        <div v-show="fifthstep">
          <div class="body">
            <!-- ローディング表示 -->
            <div v-if="loading" class="loading-overlay">
              <div class="loading-message">ローディング中<span class="loading-dots"></span></div>
            </div>
            <h3 class="textaline_center mt-2 mb-5">くじ購入の確認</h3>
            <div v-show="carderror">
              <p class="error">
                この購入はエラーにて完了しておりません。<br />クレジットカード決済の方は別のクレジットカードにて再度ご購入をお願いします。
              </p>
            </div>
            <div v-show="paidyfronterror">
              <p class="error">
                あと払い（ペイディ）の決済に失敗しました。<br />もう一度お試しいただくか、別の決済方法をお選びください。
              </p>
            </div>
            <div class="btn_conteiner unit">
              <button class="normal next" @click="comfirmBuy()">注文を確定する</button>
            </div>
            <div class="unit">
              <dl class="price">
                <dt>購入数</dt>
                <dd>{{ selectnumber }}</dd>
              </dl>
            </div>
            <div class="unit">
              <dl class="price">
                <dt>商品の小計</dt>
                <dd>￥ {{ sumPrice }}円（税込）</dd>
              </dl>
              <dl class="price">
                <dt>配送料</dt>
                <dd>￥{{ shippingFee }}円（税込・20回ごとに送料がかかります</dd>
              </dl>
              <dl class="price">
                <dt>合計</dt>
                <dd>￥ {{ totallPrice }}</dd>
              </dl>
            </div>

            <div class="unit">
              <p class="textaline_center titlebold mt-6">お届け先住所</p>
              <div class="unit_outline">
                <p class="textaline_margin">
                  〒{{ chooseaddress.postNumber }}<br />
                  住所: {{ chooseaddress.address01 }}<br />
                  {{ chooseaddress.address02 }}<br />
                  氏名: {{ chooseaddress.name }}<br />
                  電話番号: {{ chooseaddress.phoneNumber }}
                </p>
                <div class="textaline_right mb-2">
                  <button @click="changeAddress">▶︎住所を変更</button>
                </div>
              </div>
            </div>
            <p class="textaline_center titlebold mt-3">支払い方法</p>
            <div v-if="usecarrierpaymentflag" class="unit_outline">
              <p>スマートフォンキャリア決済</p>
              <button @click="changePayment">▶︎支払い方法を変更</button>
            </div>
            <div v-if="usepaidypaymentflag" class="unit_outline">
              <p class="textaline_margin">
                あと払い（ペイディ）
                <img src="img/paidy.png" alt="Paidy" class="paidy_paymentway" />
              </p>
              <div class="textaline_right mb-2">
                <button @click="changePayment">▶︎支払い方法を変更</button>
              </div>
            </div>
            <div v-else class="unit_outline">
              <p v-if="choosecard && choosecard.card" class="textaline_margin">
                カードブランド:
                <span v-if="choosecard.card.brand === 'visa'">Visa</span>
                <span v-else-if="choosecard.card.brand === 'mastercard'">Mastercard</span>
                <span v-else-if="choosecard.card.brand === 'amex'">American Express</span>
                <span v-else-if="choosecard.card.brand === 'jcb'">JCB</span>
                <span v-else-if="choosecard.card.brand === 'diners'">Diners Club</span>
                <span v-else>{{ choosecard.card.brand }}</span>
                <br />
                カード番号下4桁: {{ choosecard.card.last4 }}<br />
                有効期限: {{ choosecard.card.exp_month }}/{{ choosecard.card.exp_year }}<br />
              </p>
              <p v-else>カードが選択されていません。</p>
              <div class="textaline_right mb-2">
                <button @click="changePayment">▶︎支払い方法を変更</button>
              </div>
            </div>

            <div class="btn_conteiner unit">
              <button class="normal next" @click="comfirmBuy()">注文を確定する</button>
            </div>
            <div class="btn_conteiner unit">
              <button class="return" @click="returnsteps(6)">戻る</button>
            </div>
          </div>
        </div>

        <div v-show="sixstep">
          <div class="body">
            <!-- ローディング表示 -->
            <div v-if="isLoadingforvideo" class="loading-overlay">
              <div class="loading-message">ローディング中<span class="loading-dots"></span></div>
            </div>
            <div v-if="loadingForOrder" class="loading-overlay">
              <div class="loading-message">ローディング中<span class="loading-dots"></span></div>
            </div>
            <h3 class="resutltitle">当選結果</h3>
            <!-- ローディング中にセージを隠すために追加 -->
            <div v-if="isLoadingforvideo">
              <div style="min-height: 100px"></div>
            </div>
            <div class="unit">
              <div
                v-if="
                  order != null && videoplayflag == true && order[productindex].product_movie == 1
                "
              >
                <video
                  class="gacha_movie"
                  :src="gachaVideoUrlA"
                  autoplay
                  muted
                  playsinline
                  @loadeddata="handleLoadedData"
                  @play="handlePlay"
                  @ended="videoend"
                ></video>
              </div>

              <div
                v-if="
                  order != null && videoplayflag == true && order[productindex].product_movie == 2
                "
              >
                <video
                  class="gacha_movie"
                  :src="gachaVideoUrlB"
                  autoplay
                  muted
                  playsinline
                  @loadeddata="handleLoadedData"
                  @play="handlePlay"
                  @ended="videoend"
                ></video>
              </div>
              <div
                v-if="
                  order != null && videoplayflag == true && order[productindex].product_movie == 3
                "
              >
                <video
                  class="gacha_movie"
                  :src="gachaVideoUrlC"
                  autoplay
                  muted
                  playsinline
                  @loadeddata="handleLoadedData"
                  @play="handlePlay"
                  @ended="videoend"
                ></video>
              </div>

              <div
                class="gacha_result"
                v-if="order != null && showimageflag == true && dataLenght > productindex"
              >
                <p class="image"><img :src="order[productindex].product_image" /></p>
                <p class="name">{{ order[productindex].product_name }}</p>
                <p class="rank">{{ order[productindex].product_rank_ja }}</p>
                <div class="unit textaline_center">
                  <button class="normal next" @click="nextproduct">次のくじを引く</button>
                  <button class="normal" @click="toAllproducts">スキップして結果一覧へ</button>
                </div>
              </div>

              <div
                class="gacha_result"
                v-if="order != null && showimageflag == true && dataLenght == productindex"
              >
                <p class="image"><img :src="order[productindex].product_image" /></p>
                <p class="name">{{ order[productindex].product_name }}</p>
                <p class="rank">{{ order[productindex].product_rank_ja }}</p>
                <div class="unit textaline_center">
                  <button class="normal" @click="toAllproducts">結果へ進む</button>
                </div>
              </div>

              <div class="productcontainer" v-if="allproductsflag">
                <!-- 全ての商品を表示 -->
                <div class="subproductcontaine" v-for="(val, key) in order" :key="key">
                  <img class="productpic" :src="val['product_image']" />
                  <p class="productrank">{{ val["product_rank_ja"] }}</p>
                  <p class="productname">{{ val["product_name"] }}</p>
                </div>
                <div
                  class="btn_conteiner"
                  style="width: 100%; display: flex; justify-content: center"
                >
                  <button class="return" @click="dialogclose">閉じる</button>
                </div>
              </div>
              <!-- 
              <div v-show="isDeclinePayment">
                <h2>
                  決済に失敗しました。支払い方法を変更するか、別のクレジットカードをご使用ください。
                </h2>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
  <GoodsListDialog
    :GoodsListDialogflag="GoodsListDialogflag"
    :goodsData="goodsData"
    @goodslistdialogclose="goodslistdialogclose"
  />
</template>

<style scoped>
.modal {
  position: absolute;
  bottom: 0;
  background-color: #ffffff;
  width: 100%;
  border-radius: 15px 15px 0 0;
  box-shadow: 0px -10px 10px -5px rgba(0, 0, 0, 0.6);
  z-index: 100;
  touch-action: manipulation; /* モバイルでの拡大を禁止 */
  zoom: 1; /* ブラウザでの拡大を禁止 */
}
.modal .modal_title {
  width: 100%;
  padding: 12px 15px 10px 15px;
  text-align: center;
  font-size: 1rem;
  background: rgb(240, 172, 196);
  color: #ffffff;
  line-height: 1.2em;
  border-radius: 15px 15px 0 0;
  border-top: solid 1px rgb(255, 255, 255, 0.3);
}
.modal .inner {
  overflow: scroll;
  max-height: 200px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.modal .body {
  padding: 0px 15px;
  font-size: 0.9rem;
  line-height: 1.5em;
}
.modal .body .unit {
  margin-bottom: 15px;
}
.modal .body p {
  margin-bottom: 10px;
}

.modal .body select {
  width: 100%;
  height: 40px;
  border: none;
  border-bottom: #666 solid 2px;
  background-color: #eeeeee;
  padding: 15px 10px;
  border-radius: 0;
  font-size: 1rem;
}
.modal .btn_conteiner {
  text-align: center;
}
.modal button.normal,
.modal button.return {
  padding: 10px 15px;
  font-size: 0.9rem;
  border-radius: 100px;
  border: none;
  margin: 0 5px;
  background: rgb(240, 172, 196);
  color: #ffffff;
}
.modal button.normal:active {
  background: rgb(240, 172, 196);
}
.modal button.return {
  background: rgb(226, 226, 226);
  color: rgb(142 144 147);
}
.modal button.return:active {
  background: rgb(190, 190, 190);
  color: rgb(255 255 255);
}
.modal button.list {
  width: 100%;
  text-align: center;
  padding: 10px 15px;
  font-size: 0.9rem;
  border: none;
  background: rgb(240, 172, 196);
  color: #ffffff;
  border-bottom: solid 1px rgb(132, 163, 206);
}
.modal button.list:active {
  background: rgb(240, 172, 196);
}
.modal .unit_outline {
  background-color: #f5f5f5;
  border-radius: 20px;
  border: solid 1px rgb(226, 226, 226);
  padding: 15px 10px 1px 10px;
  margin-bottom: 30px;
  font-size: 16px;
}
.next {
  background: rgb(218, 57, 144) !important;
}
.boxtitleforpaidy {
  background: rgb(166, 0, 156) !important;
}
.textaline_center {
  text-align: center;
}
.textaline_left {
  text-align: left;
}
.textaline_right {
  text-align: right;
}
.margn_bottom_reset {
  margin-bottom: 0 !important;
}

/*アドレス追加用  */
.field {
  margin: 0 0 10px;
  vertical-align: middle;
}

.inputpostcode,
.inputtodohuken,
.inputaddress,
.inputname {
  font-size: 1rem;
  margin: 0 0 0 0px;
  outline: 1px solid;
  padding: 8px;
  border: none;
  border-bottom: #666 solid 1px;
  background-color: #eeeeee;
  outline: none;
  vertical-align: middle;
}
.inputpostcode {
  width: 100px;
}
.inputtodohuken {
  width: 100px;
}
.inputaddress {
  width: 250px;
}
.inputname {
  width: 150px;
}

.field .title {
  font-size: 0.8rem;
  display: inline-block;
  width: 80px;
  vertical-align: middle;
  margin: 0 !important;
}
dl.price {
  display: flex;
  margin: 10px 0;
}
dl.price dt {
  width: 50%;
  border-bottom: solid 1px #aaa;
  padding-bottom: 5px;
}
dl.price dd {
  width: 50%;
  text-align: right;
  border-bottom: solid 1px #aaa;
  padding-bottom: 5px;
}
.dialogtitle {
  margin: 0 0 0 0;
  /* padding: 0.5em 1em; */
  background: #fff;
  border: solid 1px;
  border-radius: 10px 10px 0 0; /*角の丸み*/
  background-color: rgb(142 144 147);
}

.background {
  /* background-color: white;
  width: 300px; */
}
.bottonconteiner {
  margin: 50px 0 20px 0;
  display: flex;
}
.addaddress {
  color: rgb(255 255 255);
  font-size: 10px;
}

.return {
  color: rgb(142 144 147);
  font-size: 12px;
}
.gacha_movie {
  width: 100%;
}
.gacha_result {
}
.gacha_result .image {
  text-align: center;
}
.gacha_result .image img {
  width: 50%;
}
.gacha_result .name {
  text-align: center;
}
.gacha_result .rank {
  text-align: center;
}
.productcontainer {
  display: flex;
  /*コレ*/
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.subproductcontaine {
  border: 1px solid rgb(216, 216, 216);
  padding: 10px;
  margin: 10px 1.5% 10px 1.5%;
  width: 30%;
  text-align: center;
  border-radius: 8px;
}
.productpic {
  width: 100%;
  margin-bottom: 0.7em;
}
.productname {
  font-size: 0.8rem;
}
.productrank {
  font-size: 0.8rem;
}
.modal .btn_conteiner {
  display: flex;
  justify-content: center;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.5); /* 半透明の灰色背景色 */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* 追加：z-indexプロパティ */
}

.body {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.unit {
  margin-bottom: 20px;
}

.number-selection {
  display: flex;
  align-items: center;
}

.quantity-input {
  display: inline-block;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #f5f5f5;
  cursor: pointer;
}

.quantity-input-field {
  display: inline-block;
}

.quantity-input-field input {
  width: 40px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.quantity-input-field-placeholder {
  display: inline-block;
  color: #999;
  cursor: pointer;
}

.quantity-buttons {
  display: flex;
  margin-left: 10px;
}

.quantity-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: none;
  background-color: #f5f5f5;
  cursor: pointer;
}

.quantity-button:focus {
  outline: none;
}

.textaline_right {
  text-align: right;
}

.error {
  color: red;
  font-size: 10px;
}

.number-display {
  border: 3px solid black;
  padding: 10px;
  display: inline-block;
  text-align: center;
  width: 100px;
  height: 50px;
}

.quantity-button {
  margin: 0 5px; /* 0は上下のマージン、10pxは左右のマージン */
  width: 50px; /* ボタンの幅 */
  height: 50px; /* ボタンの高さ */
}
.number-selection {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
}

.control-group {
  /* 追加 */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.quantity-buttons {
  display: flex;
}

.preset-buttons {
  display: flex;
  justify-content: flex-start;
}
.preset-button {
  background-color: rgb(226, 226, 226);
  padding: 5px; /* ボタン内のスペース */
  margin: 5px; /* ボタン間のスペース */
  border-radius: 5px; /* ボタンの角丸 */
  width: 50px;
}
.titlebold {
  font-weight: bold;
}
.textaline_margin {
  margin-left: 15px;
}
@keyframes ellipsis {
  0% {
    content: " .  ";
  }
  33% {
    content: " .. ";
  }
  67% {
    content: " ...";
  }
  100% {
    content: "    ";
  }
}

.loading-message {
  color: white; /* 白色の文字色 */
  font-size: 24px;
  font-weight: bold;
}

.loading-dots::after {
  content: "";
  display: inline-block;
  width: 1em;
  animation: ellipsis 2s infinite;
  color: white; /* 白色の文字色 */
}
.attention {
  font-size: 8px;
}
.resutltitle {
  text-align: center;
}

.paidy-icon {
  height: 20px; /* アイコンの高さを調整 */
  margin-left: 8px; /* テキストとの間隔を調整 */
}

.paidy_paymentway {
  height: 35px; /* アイコンの高さを調整 */
  /* margin-left: 3px; テキストとの間隔を調整 */
}

@media (min-width: 960px) {
  .modal {
    width: 48%;
    height: 400px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-2%, -50%);
    border-radius: 15px;
  }
  .modal .inner {
    max-height: 300px;
    overflow: auto;
  }
  .unit_outline {
    background-color: #f5f5f5;
    border-radius: 20px;
    border: solid 1px rgb(226, 226, 226);
    padding: 15px 20px 10px 20px;
    margin-bottom: 30px;
    margin-left: 15%;
    margin-right: 15%;
    font-size: 16px;
  }
}
</style>

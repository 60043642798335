<script setup>
// eslint-disable-next-line no-undef
// import { connectFirestoreEmulator } from "@firebase/firestore";
import { ref, watch } from "vue";
import axios from "axios";

const emit = defineEmits(["sendaddress", "addaddressclose"]);
const jsonpAdapter = require("axios-jsonp");

const loadingforaddaddress = ref(false);

const postcode = ref(null);
const todohuken = ref(null);
const moreaddress = ref(null);
const address1 = ref(null);
const address2 = ref(null);
const name = ref(null);
const nameSei = ref(null);
const nameMei = ref(null);
// name.value = nameSei.value + " " + nameMei.value;
const phoneno = ref(null);

const postcodeError = ref(null);
const phoneError = ref(null);

const getaddressfrompostcode = () => {
  const apiURL = "https://api.zipaddress.net/?zipcode=" + postcode.value;
  axios.get(apiURL, { adapter: jsonpAdapter }).then(async (res) => {
    const response = res.data;
    todohuken.value = response.pref;
    if (response.city) {
      todohuken.value = response.pref;
      moreaddress.value = response.city + response.town;
    } else {
      // postcodeError.value = "郵便番号が間違っています。";
    }
  });
};

const validatePostcode = () => {
  if (!postcode.value || postcode.value.toString().length != 7) {
    postcodeError.value = "郵便番号はハイフンなしの7桁で入力してください。";
  } else {
    postcodeError.value = null;
  }
};

const validatePhone = () => {
  if (!phoneno.value || phoneno.value.toString().length < 9) {
    phoneError.value = "電話番号を入力してください。";
  } else {
    phoneError.value = null;
  }
};

watch(postcode, validatePostcode);
watch(phoneno, validatePhone);

const sendaddress = () => {
  loadingforaddaddress.value = true;
  address1.value = todohuken.value + moreaddress.value;
  emit("sendaddress", postcode, address1, address2, name, phoneno);
};
const addaddressclose = () => {
  loadingforaddaddress.value = false;
  emit("addaddressclose");
};

watch([nameSei, nameMei], () => {
  name.value = nameSei.value + " " + nameMei.value;
});
</script>

<template>
  <div v-if="loadingforaddaddress" class="loading-overlay">
    <div class="loading-message">ローディング中<span class="loading-dots"></span></div>
  </div>
  <div class="maincontainer">
    <v-row class="dialogtitle pt-5 pb-5" height="100px" justify="center">
      新しいお届け先情報の追加
    </v-row>
    <div class="background">
      <div class="field">
        <p class="title">郵便番号</p>
        <input
          type="text"
          v-model="postcode"
          @input="getaddressfrompostcode"
          required
          class="inputpostcode"
        />
        <p class="attention">※郵便番号を入力すると住所は自動で入力されます</p>
        <p class="error" v-if="postcodeError">{{ postcodeError }}</p>
      </div>
      <div class="field">
        <p class="title">都道府県</p>
        <input type="text" v-model="todohuken" required class="inputtodohuken" />
      </div>
      <div class="field">
        <p class="title">ご住所</p>
        <input type="text" v-model="moreaddress" required class="inputaddress" />
      </div>
      <div class="field">
        <p class="title">建物名・部屋番号</p>
        <input type="text" v-model="address2" required class="inputaddress" />
      </div>
      <div class="field">
        <p class="title">姓</p>
        <input type="text" v-model="nameSei" required class="inputname" />
      </div>
      <div class="field">
        <p class="title">名</p>
        <input type="text" v-model="nameMei" required class="inputname" />
      </div>
      <div class="field">
        <p class="title">電話番号</p>
        <input type="text" v-model="phoneno" required class="inputname" />
        <p class="error" v-if="phoneError">{{ phoneError }}</p>
      </div>
      <div class="bottonconteiner">
        <div class="">
          <v-btn class="ml-13" height="30" width="80" rounded="pill" color="rgba(226, 226, 226)">
            <a class="return" @click="addaddressclose()">キャンセル</a>
          </v-btn>
        </div>
        <div>
          <v-btn
            class="ml-5"
            height="30"
            width="80"
            rounded="pill"
            @click="sendaddress()"
            color="rgb(231, 192, 255)"
          >
            <a class="addaddress">住所を追加</a>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.field {
  margin: 20px 0 0 20px;
}

.inputpostcode {
  font-size: 10px;
  margin: 0 0 0 0px;
  outline: 1px solid;
  padding: 5px 5px 5px 5px;
  width: 100px;
  height: 20px;
}
.inputtodohuken {
  font-size: 10px;
  margin: 0 0 0 0px;
  outline: 1px solid;
  padding: 5px 5px 5px 5px;
  width: 100px;
  height: 20px;
}
.inputaddress {
  font-size: 10px;
  margin: 0 0 0 0px;
  outline: 1px solid;
  padding: 5px 5px 5px 5px;
  width: 250px;
  height: 20px;
}

.title {
  font-size: 12px;
}

.dialogtitle {
  margin: 0 0 0 0;
  /* padding: 0.5em 1em; */
  background: #fff;
  border: solid 1px;
  border-radius: 10px 10px 0 0; /*角の丸み*/
  background-color: rgb(142 144 147);
}

.background {
  background-color: white;
  width: 300px;
  border: solid 1px;
  border-radius: 0px 0px 10px 10px; /*角の丸み*/
}
.inputname {
  font-size: 10px;
  margin: 0 0 0 0px;
  outline: 1px solid;
  padding: 5px 5px 5px 5px;
  width: 150px;
  height: 20px;
}
.bottonconteiner {
  margin: 50px 0 20px 0;
  display: flex;
}
.addaddress {
  color: rgb(255 255 255);
  font-size: 10px;
}

.return {
  color: rgb(142 144 147);
  font-size: 12px;
}

.error {
  color: red;
  font-size: 10px;
}
@keyframes ellipsis {
  0% {
    content: " .  ";
  }
  33% {
    content: " .. ";
  }
  67% {
    content: " ...";
  }
  100% {
    content: "    ";
  }
}

.loading-message {
  color: white; /* 白色の文字色 */
  font-size: 24px;
  font-weight: bold;
}

.loading-dots::after {
  content: "";
  display: inline-block;
  width: 1em;
  animation: ellipsis 2s infinite;
  color: white; /* 白色の文字色 */
}
.attention {
  font-size: 8px;
}
</style>

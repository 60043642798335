<script setup>
import { toRefs, computed, ref } from "vue";
// import { useRouter } from "vue-router";

const props = defineProps({
  goodsData: Array,
  winningRateSur: String,
  winningRateSsr: String,
  winningRateR: String,
  winningRateA: String,
  winningRateB: String,
  winningRateC: String,
});

const { goodsData } = toRefs(props);
// const liveData = ref([{ live_status: "live", eventtitle: "タイトル" }]);

const surGoodsData = computed(() => {
  return goodsData.value
    .filter((goods) => goods.product_rank === "sur")
    .sort((a, b) => extractNumber(a.id) - extractNumber(b.id));
});

const ssrGoodsData = computed(() => {
  return goodsData.value
    .filter((goods) => goods.product_rank === "ssr")
    .sort((a, b) => extractNumber(a.id) - extractNumber(b.id));
});

const rGoodsData = computed(() => {
  return goodsData.value
    .filter((goods) => goods.product_rank === "r")
    .sort((a, b) => extractNumber(a.id) - extractNumber(b.id));
});

const aGoodsData = computed(() => {
  return goodsData.value
    .filter((goods) => goods.product_rank === "a")
    .sort((a, b) => extractNumber(a.id) - extractNumber(b.id));
});

const bGoodsData = computed(() => {
  return goodsData.value
    .filter((goods) => goods.product_rank === "b")
    .sort((a, b) => extractNumber(a.id) - extractNumber(b.id));
});

const cGoodsData = computed(() => {
  return goodsData.value
    .filter((goods) => goods.product_rank === "c")
    .sort((a, b) => extractNumber(a.id) - extractNumber(b.id));
});

// 🔽 文字列 "r-1", "r-10" の数字部分を抽出して数値に変換する関数
function extractNumber(id) {
  const match = id.match(/\d+/); // "r-10" → "10"
  return match ? parseInt(match[0], 10) : 0; // "10" → 10
}

//商品モーダル
const isModalOpen = ref(false);
const selectedProduct = ref({});
const openProductModal = (product) => {
  selectedProduct.value = product;
  isModalOpen.value = true;
  // const router = useRouter();
  // router.push({ name: "GoodsList" });
};
// モーダルを閉じる関数
const closeModal = () => {
  isModalOpen.value = false;
};
</script>
-->

<template>
  <!-- <div class="modal"> -->
  <!-- <div class="modal_title">限定グッズ一覧</div> -->
  <div class="inner">
    <div v-if="surGoodsData.length > 0">
      <div class="titleBoxSsr">
        <span class="image"><img src="img/goodsListTitle_sur.png" /></span>
        <span class="main">スーパーウルトラレア賞</span>
        <span class="sub">当選確率:{{ winningRateSur }}%</span>
      </div>
      <div class="productcontainer">
        <div
          class="subproductcontaineforoverssr"
          v-for="(goods, index) in surGoodsData"
          :key="index"
          @click="openProductModal(goods)"
        >
          <img class="productpic" :src="goods.product_image" contain />
          <div class="productname">
            {{ goods.product_name }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="ssrGoodsData.length > 0">
      <div class="titleBoxSsr">
        <span class="image"><img src="img/goodsListTitle_ssr.png" /></span>
        <span class="main">スーパーレア賞</span>
        <span class="sub">当選確率:{{ winningRateSsr }}%</span>
      </div>
      <!-- スーパーウルトラレアがある場合はPCの場合画像を小さく 分岐 -->
      <div class="productcontainer" v-if="surGoodsData.length > 0">
        <div
          class="subproductcontaine bigpicforssrformobile"
          v-for="(goods, index) in ssrGoodsData"
          :key="index"
          @click="openProductModal(goods)"
        >
          <img class="productpic" :src="goods.product_image" contain />
          <div class="productname">
            {{ goods.product_name }}
          </div>
        </div>
      </div>
      <div class="productcontainer" v-if="surGoodsData.length == 0">
        <div
          class="subproductcontaineforoverssr"
          v-for="(goods, index) in ssrGoodsData"
          :key="index"
          @click="openProductModal(goods)"
        >
          <img class="productpic" :src="goods.product_image" contain />
          <div class="productname">
            {{ goods.product_name }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="rGoodsData.length > 0">
      <div class="titleBoxSsr">
        <span class="image"><img src="img/goodsListTitle_r.png" /></span>
        <span class="main">レア賞</span>
        <span class="sub">当選確率:{{ winningRateR }}%</span>
      </div>
      <div class="productcontainer">
        <div
          class="subproductcontaine"
          v-for="(goods, index) in rGoodsData"
          :key="index"
          @click="openProductModal(goods)"
        >
          <img class="productpic" :src="goods.product_image" contain />
          <div class="productname">
            {{ goods.product_name }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="aGoodsData.length > 0">
      <div class="titleBoxSsr">
        <span class="image"><img src="img/goodsListTitle_a.png" /></span>
        <span class="main">A賞</span>
        <span class="sub">当選確率:{{ winningRateA }}%</span>
      </div>
      <div class="productcontainer">
        <div
          class="subproductcontaine"
          v-for="(goods, index) in aGoodsData"
          :key="index"
          @click="openProductModal(goods)"
        >
          <img class="productpic" :src="goods.product_image" contain />
          <div class="productname">
            {{ goods.product_name }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="bGoodsData.length > 0">
      <div class="titleBoxSsr">
        <span class="image"><img src="img/goodsListTitle_b.png" /></span>
        <span class="main">B賞</span>
        <span class="sub">当選確率:{{ winningRateB }}%</span>
      </div>
      <div class="productcontainer">
        <div
          class="subproductcontaine"
          v-for="(goods, index) in bGoodsData"
          :key="index"
          @click="openProductModal(goods)"
        >
          <img class="productpic" :src="goods.product_image" contain />
          <div class="productname">
            {{ goods.product_name }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="cGoodsData.length > 0">
      <div class="titleBoxSsr">
        <span class="image"><img src="img/goodsListTitle_c.png" /></span>
        <span class="main">C賞</span>
        <span class="sub">当選確率:{{ winningRateC }}%</span>
      </div>
      <div class="productcontainer">
        <div
          class="subproductcontaine"
          v-for="(goods, index) in cGoodsData"
          :key="index"
          @click="openProductModal(goods)"
        >
          <img class="productpic" :src="goods.product_image" contain />
          <div class="productname">
            {{ goods.product_name }}
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="btn_conteiner unit">
      <button class="return" @click="goodslistdialogclose">閉じる</button>
    </div> -->
  </div>
  <!-- </div> -->

  <!-- <div class="titleBoxSsr">
    <div class="text">スーパーレア賞</div>
  </div>
  <div class="productcontainer">
    <div class="subproductcontaine" v-for="(goods, index) in ssrGoodsData" :key="index">
      <img class="productpic" :src="goods.product_image" contain />
      <div class="productname">
        {{ goods.product_name }}
      </div>
    </div>
  </div> -->
  <!-- モーダルウィンドウ -->
  <div v-if="isModalOpen" class="modal">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <img :src="selectedProduct.product_image" class="modal-image" />
      <div class="modal-productname">{{ selectedProduct.product_name }}</div>
      <div class="modal-productsize">サイズ：{{ selectedProduct.product_size }}</div>
      <div class="modal-productsize">素材：{{ selectedProduct.product_material }}</div>
    </div>
  </div>
</template>

<style scoped>
.maincontainer {
  margin: 10px 0 15px 0;
}

.livecontainer {
  border: solid 1px #454545;
}
.livestatus {
  border: solid 1px red;
  display: inline-block;
  float: left;
  margin: 15px 0 5px 20px;
  background-color: red;
  color: white;
  padding: 5px 5px 5px 5px;
  font-size: 12px;
  /* text-align: left; */
}
.title {
  clear: both;
  margin: 5px 0 5px 20px;
  text-align: left;
}
.time {
  margin: 5px 0 15px 20px;
  text-align: left;
}
.livestatusinprogress {
  border: solid 1px blue;
  display: inline-block;
  float: left;
  margin: 15px 0 5px 20px;
  background-color: blue;
  color: white;
  padding: 5px 5px 5px 5px;
  font-size: 12px;
}
.titleBoxSsr {
  background-color: rgb(242, 242, 242);
  padding: 10px 10px 10px 10px;
}

.productcontainer {
  display: flex;
  /*コレ*/
  flex-wrap: wrap;
}

.productpic {
  width: 50%;
}
.productname {
  font-size: 10px;
}

.maincontainer {
  margin: 10px 0 15px 0;
}

.livecontainer {
  border: solid 1px #454545;
}
.livestatus {
  border: solid 1px red;
  display: inline-block;
  float: left;
  margin: 15px 0 5px 20px;
  background-color: red;
  color: white;
  padding: 5px 5px 5px 5px;
  font-size: 12px;
  /* text-align: left; */
}
.title {
  clear: both;
  margin: 5px 0 5px 20px;
  text-align: left;
}
.time {
  margin: 5px 0 15px 20px;
  text-align: left;
}
.livestatusinprogress {
  border: solid 1px blue;
  display: inline-block;
  float: left;
  margin: 15px 0 5px 20px;
  background-color: blue;
  color: white;
  padding: 5px 5px 5px 5px;
  font-size: 12px;
}
.titleBoxSsr {
  /* background: rgb(248, 245, 227); */
  /* background: rgb(242, 242, 242); */
  background: rgb(240, 172, 196);
  padding: 10px 10px 10px 30px;
  margin: 0px 1.5% 10px 30px;
  position: relative;
  color: rgb(105, 105, 105);
}
.titleBoxSsr .image {
  display: inline-block;
  width: 40px;
  margin-right: 10px;
  position: absolute;
  top: -20px;
  left: -20px;
}
.titleBoxSsr .image img {
  height: 170%;
  width: 170%;
  margin-right: 0px;
}
.titleBoxSsr .main {
  font-size: 1.2rem;
  margin-right: 60px;
  font-weight: bold;
}
.titleBoxSsr .sub {
  font-size: 0.9rem;
  position: absolute;
  right: 10px;
}

.productcontainer {
  display: flex;
  /*コレ*/
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: center; /* 中央揃え */
}
.subproductcontaine {
  border: 1px solid rgb(216, 216, 216);
  padding: 10px;
  margin: 10px 1.5% 10px 1.5%;
  width: 30%;
  text-align: center;
  border-radius: 8px;
}
.subproductcontaineforoverssr {
  border: 0.5px solid rgb(216, 216, 216);
  padding: 10px;
  margin: 10px 1.5% 10px 1.5%;
  width: 50%;
  text-align: center;
  border-radius: 8px;
}
.productpic {
  width: 100%;
  margin-bottom: 0.5em;
}
.productname {
  font-size: 0.8rem;
}

.modal {
  position: absolute;
  bottom: 0;
  background-color: #ffffff;
  width: 100%;
  border-radius: 15px 15px 0 0;
  box-shadow: 0px -10px 10px -5px rgba(0, 0, 0, 0.6);
  z-index: 100;
}
.modal .modal_title {
  width: 100%;
  padding: 12px 15px 10px 15px;
  text-align: center;
  font-size: 1rem;
  background: rgb(89, 117, 157);
  color: #ffffff;
  line-height: 1.2em;
  border-radius: 15px 15px 0 0;
  border-top: solid 1px rgb(255, 255, 255, 0.3);
}
.modal .inner {
  overflow: scroll;
  max-height: 500px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.modal .body {
  padding: 0px 15px;
  font-size: 0.9rem;
  line-height: 1.5em;
}
.modal .body .unit {
  margin-bottom: 15px;
}
.modal .body p {
  margin-bottom: 10px;
}

.modal .body select {
  width: 100%;
  height: 40px;
  border: none;
  border-bottom: #666 solid 2px;
  background-color: #eeeeee;
  padding: 15px 10px;
  border-radius: 0;
  font-size: 1rem;
}
.modal .btn_conteiner {
  text-align: center;
}
.modal button.normal,
.modal button.return {
  padding: 10px 15px;
  font-size: 0.9rem;
  border-radius: 100px;
  border: none;
  margin: 0 5px;
  background: rgb(89, 117, 157);
  color: #ffffff;
}
.modal button.normal:active {
  background: rgb(46, 68, 99);
}
.modal button.return {
  background: rgb(226, 226, 226);
  color: rgb(142 144 147);
}
.modal button.return:active {
  background: rgb(190, 190, 190);
  color: rgb(255 255 255);
}
.modal button.list {
  width: 100%;
  text-align: center;
  padding: 10px 15px;
  font-size: 0.9rem;
  border: none;
  background: rgb(89, 117, 157);
  color: #ffffff;
  border-bottom: solid 1px rgb(132, 163, 206);
}
.modal button.list:active {
  background: rgb(46, 68, 99);
}
.modal .unit_outline {
  border-radius: 10px;
  border: solid 1px rgb(126, 126, 126);
  padding: 15px 10px 15px 10px;
  margin-bottom: 10px;
}

.textaline_center {
  text-align: center;
}
.textaline_left {
  text-align: left;
}
.textaline_right {
  text-align: right;
}
.margn_bottom_reset {
  margin-bottom: 0 !important;
}

/* モーダルウィンドウのスタイル */
.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 2000px;
  text-align: center;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-image {
  width: 100%;
  max-width: 700px;
  margin-bottom: 20px;
}

.modal-productname {
  font-size: 1.2rem;
  font-weight: bold;
}

.modal-productsize {
  font-size: 1rem;
  margin-top: 5px;
}

/* モバイル用のスタイル */
@media (max-width: 480px) {
  .modal-content {
    width: 90%;
    max-width: 90%;
    padding: 10px;
    margin-top: 50%; /* 上からの余白を追加 */
  }
  .modal-image {
    max-width: 100%;
  }
  .titleBoxSsr .main {
    font-size: 0.9rem;
    margin-right: 60px;
    font-weight: bold;
  }
  .titleBoxSsr .sub {
    font-size: 0.8rem;
    position: absolute;
    right: 10px;
  }
  .bigpicforssrformobile {
    width: 50;
  }
}
</style>
